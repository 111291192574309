<div *ngIf="imagenes.length > 0" class="row">
   <div class="col-4"></div>
   <div *ngIf="imagenesCarrusel.length > 0" class="col-4">
       <div id="carouselExample" class="carousel slide clickable">
           <div  class="carousel-inner">
                   <div class="carousel-item active text-center">
                       <img (click)="cargarFotoGrande(imagenCarrusel)" src="{{imagenCarrusel.ruta}}" class="d-block imagenCarrusel" >
                   </div>
                   <div *ngFor="let imagen of imagenesCarrusel" class="carousel-item text-center">
                       <img (click)="cargarFotoGrande(imagen)" src="{{imagen.ruta}}" class="d-block imagenCarrusel" >
                   </div>
           </div>
           <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
             <span class="carousel-control-prev-icon" aria-hidden="true"></span>
             <span class="visually-hidden">Previous</span>
           </button>
           <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
             <span class="carousel-control-next-icon" aria-hidden="true"></span>
             <span class="visually-hidden">Next</span>
           </button>
         </div>
   </div>

   <div *ngIf="imagenesCarrusel.length == 0"class="col-4">
      <div class="text-center">
         <img (click)="cargarFotoGrande(imagenCarrusel)" src="{{imagenCarrusel.ruta}}" class="d-block imagenCarrusel" >
     </div>
   </div>
   <div class="col-4"></div>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                            IMÁGEN EN GRANDE                             -->
<!-- ----------------------------------------------------------------------- -->

<div *ngIf="mostrarFotoGrande" class="fotoGrande fade-in-image traerAlFrente text-center">
   <div class="row">
       <div class="col-lg-11"></div>

       <button (click)="cancelarImagenGrande();" type="button" class="btn col-lg-1">
                   
           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
               <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
             </svg>
           
           </button>

   </div>
   <div>
       <img src="{{fotoGrande.ruta}}" class="" >
   </div>
   <!-- <a href="{{fotoGrande.ruta}}" download="foto.jpg">Descargar foto</a> -->
   <br>
   <div class="row">
       <app-botonera class="botonera" [botones]="['borrar', 'descargar']" (borrarClick)="borrarImagenGrande(fotoGrande.id!)" (descargarClick)="descargarImagen(fotoGrande.ruta!, fotoGrande.archivo!)"></app-botonera>
   </div>
</div>

<app-mensaje [mensaje]=mensaje (botonesClick)="controlBotones($event)"></app-mensaje>
<app-spinner *ngIf="activarSpinner"></app-spinner>

